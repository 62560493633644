
import Layout from "@/Layout/Layout.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Layout },
  name: "Contact",
  mounted() {
    window.scrollTo({ top: 0 });
  },
  data() {
    return {
      contacts: [
        { icon: "facebook", social: "@support_withHalal" },
        { icon: "twitter", social: "@support_WithHalal" },
        { icon: "mail", social: "Support@withhalal.com" },
      ],
    };
  },
});
